export default {
    name: "underlyingchart",
    created() {
        window.getUnderlyingChartData = this.getUnderlyingChartData;
    },
    mounted() {
        this.$nextTick(function () {
            this.$js.jqcombobox("#chart-combobox");
            $(".selector_ma").selectmenu({
                width: "100%"
            });
            $(".selector_bb").selectmenu({
                width: "100%"
            });
            this.getRicList();
            // $(".details-btn div:first-child").addClass("details-btn-bg");
            $(".selector_ma").selectmenu("disable");
            this.selectSort();
            let _this = this
            $(".input_rsi").change(function (e) {
                _this.getUnderlyingChartData();
            });
            $(".selector_ma").on('selectmenuchange', function () {
                _this.getUnderlyingChartData();
            });
            $(".selector_bb").on('selectmenuchange', function () {
                if ($(".checkbox_bb").is(':checked'))
                    _this.getUnderlyingChartData();
            });
            $(".checkbox_bb").change(function (e) {
                _this.getUnderlyingChartData();
            });
            this.$js.ScreenMonitor()
        });
    },
    data() {
        return {
            chartDate: 'm',
            chartDateOld: '',
            chartRic: "",
            current_dsply_nmll: "",
            postData: {
                underlyingNanme: "",
                sortBy: "isr",
                sortOrder: "desc"
            },
            judge: false,
            fullchartArr: [],
            chartArr: []
        };
    },
    methods: {
        //获取ric列表
        getRicList() {
            $.ajax({
                type: "GET",
                url: this.$js.webservice + "GetScreenerData",
                data: {
                    token: "webkey",
                    init: "1"
                },
                dataType: "json",
                success: data => {
                    if (data.status == "SUCCESS") {
                        this.underlyingTickerChange();
                        //fix start
                        let parmUrl = location.search.substr(1).split("dsplay_nmll=")[1];
                        $.each(data.underlyings, (index, comment) => {
                            if (parmUrl != undefined && comment['underlying_ticker'] == parmUrl)
                                localStorage.setItem("underlyingRic", comment['underlying_ric']);
                        });
                        //fix end
                        if (localStorage.getItem("underlyingRic") !== null) {
                            var ric = localStorage.getItem("underlyingRic");
                            var ticker = "";
                            $.each(data.underlyings, (index, comment) => {
                                $("#chart-combobox").append(
                                    '<option value="' +
                                    comment["underlying_ric"] +
                                    "+" +
                                    comment["underlying_name"] +
                                    " (" +
                                    comment["underlying_ticker"] +
                                    ")" +
                                    '">' +
                                    "(" +
                                    comment["underlying_ticker"] +
                                    ") " +
                                    comment["underlying_name"] +
                                    "</option>"
                                );
                                if (comment.underlying_ric == ric || comment.underlying_ticker == ric) {
                                    this.chartRic = comment["underlying_ric"];
                                    $(".ui-widget")
                                        .find("input")
                                        .val(
                                            "(" +
                                            comment["underlying_ticker"] +
                                            ") " +
                                         comment["underlying_name"]
                                        );
                                    //改变meta的keywords
                                    $('meta[name="keywords"]').attr('content', "warrant details, warrant terms, RHB, malaysia warrants," + comment["underlying_ticker"] + "," + comment["underlying_code"]);
                                    $(".chart-text div").html(
                                        comment["underlying_name"] +
                                        " (" +
                                        comment["underlying_ticker"] +
                                        ")"
                                    );
                                    this.postData.underlyingNanme = comment["underlying_ticker"];
                                    this.getSearchData();
                                }
                            });
                        } else {
                            $.each(data.underlyings, (index, comment) => {
                                if (index == 0) {
                                    this.chartRic = comment["underlying_ric"];
                                    $(".ui-widget")
                                        .find("input")
                                        .val(
                                            " (" +
                                            comment["underlying_code"] +
                                            ") " + comment["underlying_name"]
                                        );

                                    //改变meta的keywords
                                    $('meta[name="keywords"]').attr('content', "warrant details, warrant terms, RHB, malaysia warrants," + comment["underlying_ticker"] + "," + comment["underlying_code"]);
                                    $(".chart-text div").html(
                                        comment["underlying_name"] +
                                        " (" +
                                        comment["underlying_ticker"] +
                                        ")"
                                    );
                                    this.postData.underlyingNanme = comment["underlying_ticker"];
                                    this.getSearchData();
                                }
                                $("#chart-combobox").append(
                                    '<option value="' +
                                    comment["underlying_ric"] +
                                    "+" +
                                    comment["underlying_name"] +
                                    " (" +
                                    comment["underlying_ticker"] +
                                    ")" +
                                    '">' +
                                    "(" +
                                    comment["underlying_ticker"] +
                                    ") " +
                                    comment["underlying_name"] +
                                    "</option>"
                                );
                            });
                        }
                        $(document).ready(function () {
                            $(".ui-autocomplete").css({
                                'max-width': $('.ui-widget').width() + "px"
                            });
                        });
                        this.getMarketData();
                        this.getUnderlyingChartData();
                        this.$js.clickEliminate('.ui-widget', true)
                        console.log(this.chartRic);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        underlyingTickerChange() {
            let _this = this;
            $("#chart-combobox").combobox({
                select: function (event, ui) {
                    $(".chart-text div").html(this.value.split("+")[1]);
                    _this.postData.underlyingNanme = $(this)
                        .find(":selected")
                        .text()
                        .split(" ")[0];
                    this.chartRic = this.value.split("+")[0];
                    //getSearchData();
                    //getUnderlyingChartData();
                    //getMarketData();
                    localStorage.setItem("underlyingRic", this.chartRic);
                    location.href =
                        "underlyingchart?dsplay_nmll=" +
                        $(this)
                            .find(":selected")
                            .text()
                            .split(" ")[0];
                }
            });
        },
        //时间点击
        dateClick(e, index) {
            this.chartDateOld = this.chartDate
            this.chartDate = e;
            $('.details-btn').find('div').eq(index).addClass('details-btn-bg')
            $('.details-btn').find('div').eq(index).siblings().removeClass('details-btn-bg')
            if (this.chartDate == 'd') {
                $(".selector_ma").selectmenu("disable");
            } else {
                $(".selector_ma").selectmenu("enable");
            }
            if (this.chartDate == 'd' && this.judge) {
                window.setTimeout(function () {
                    this.getUnderlyingChartData();
                }, 3000)
            } else {
                if (index == 0) {
                    this.getUnderlyingChartData();
                } else {
                    if (this.chartDateOld == 'd') {
                        this.getUnderlyingChartData();
                    } else {
                        this.underlyingChartLoad(this.chartArr, this.fullchartArr);
                    }

                }
            }
        },
        //market data
        getMarketData() {
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$js.webservice +
                    "GetMarketData?token=webkey&type=underlyingdata&ric=" +
                    this.chartRic,
                success: res => {
                    if (res.status == "SUCCESS") {
                        if (res[this.chartRic] !== {}) {
                            //TRDPRC_1 + NETCHNG_1 + PCTCHNG + ACVOL_1
                            $(".uc-currery").html("&nbsp;" + this.$js.curreryRetrun(res[this.chartRic].CURRENCY) + "&nbsp;");

                            this.current_dsply_nmll = res[this.chartRic].DSPLY_NMLL;
                            $(".table_name span").html(res[this.chartRic].DSPLY_NMLL);
                            $(".BID").html(
                                this.$js.formatNumber(res[this.chartRic].TRDPRC_1, 0, 1)
                            );
                            $(".BID_NETCHNG").html(
                                this.$js.formatNumber(res[this.chartRic].NETCHNG_1, 0, 0)
                            );
                            $(".BID_PCTCHNG").html(
                                this.$js.formatNumber(res[this.chartRic].PCTCHNG, 1, 0)
                            );
                            $(".TURNOVER").html(
                                this.$js.formatNumber(
                                    this.$js.clearRM(res[this.chartRic].ACVOL_1),
                                    0,
                                    1
                                )
                            );
                            //两个值判断正负和颜色
                            if ($(".BID_NETCHNG").text() > 0) {
                                $(".BID_NETCHNG").css("color", "#009900");
                                $(".BID_NETCHNG").html("+" + $(".BID_NETCHNG").html());
                            } else if ($(".BID_NETCHNG").html() < 0) {
                                $(".BID_NETCHNG").css("color", "#FF0000");
                            }
                            $(".BID_NETCHNG").html(
                                "&nbsp;(" + $(".BID_NETCHNG").html() + ")"
                            );
                            if ($(".BID_PCTCHNG").html() > 0) {
                                $(".BID_PCTCHNG").css("color", "#009900");
                                $(".BID_PCTCHNG").html("+" + $(".BID_PCTCHNG").html() + " %");
                            } else if ($(".BID_PCTCHNG").html() < 0) {
                                $(".BID_PCTCHNG").css("color", "#FF0000");
                                $(".BID_PCTCHNG").html($(".BID_PCTCHNG").html() + " %");
                            }
                        }
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        // underlying chart
        getUnderlyingChartData() {
            let _this = this;
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$js.webservice +
                    "GetChartData?token=webkey&type=1&period=" +
                    this.chartDate +
                    "&ric=" +
                    this.chartRic,
                success: (res) => {
                    if (res.status == "SUCCESS") {
                        this.fullchartArr = [];
                        this.chartArr = []
                        if (res.underlyingDataList.length > 0) {
                            $.each(res.underlyingDataList, (index, datas) => {
                                var arr = [];
                                if (this.chartDate == 'd') {
                                    arr.push(_this.$js.changeToLocalTime(datas.time));
                                } else {
                                    arr.push(_this.$js.changeToLocalTime(datas.date));
                                }
                                arr.push(datas.open);
                                arr.push(datas.high);
                                arr.push(datas.low);
                                arr.push(datas.close);
                                arr.push(datas.volume);
                                this.fullchartArr.push(arr);
                                if (this.chartDate == 'd') {
                                    this.chartArr.push(arr);
                                } else if (this.chartDate == 'w') {
                                    var currentdate = new Date().getTime();
                                    var jsondate = new Date(datas.date).getTime();
                                    var diff = 1000 * 60 * 60 * 24 * 7;
                                    if (currentdate - diff < jsondate) this.chartArr.push(arr);
                                } else if (this.chartDate == 'm') {
                                    var currentdate = new Date().getTime();
                                    var jsondate = new Date(datas.date).getTime();
                                    var diff = 1000 * 60 * 60 * 24 * 30;
                                    if (currentdate - diff < jsondate) this.chartArr.push(arr);
                                } else if (this.chartDate == 'y') {
                                    var currentdate = new Date().getTime();
                                    var jsondate = new Date(datas.date).getTime();
                                    var diff = 1000 * 60 * 60 * 24 * 365;
                                    if (currentdate - diff < jsondate) this.chartArr.push(arr);
                                }
                            });
                            _this.underlyingChartLoad(this.chartArr, this.fullchartArr);
                        } else {
                            $('.details-btn').find('div').removeClass("details-btn-bg")
                            $('.details-btn').find('div').eq(2).addClass("details-btn-bg")
                            if ($('.details-btn').find('div').eq(2).html().substring(1, 2).toLowerCase() === 'd') {
                                _this.chartDate = 'm';
                            } else {
                                _this.chartDate = 'm';
                            }
                            _this.getUnderlyingChartData()
                            _this.judge = true
                        }
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        underlyingChartLoad(chartArr, fullchartArr) {
            let _this = this;
            $("#chart-container").empty(); //先清空
            anychart.onDocumentReady(function () {
                // create data table on loaded data
                var dataTable = anychart.data.table();
                dataTable.addData(fullchartArr);
                var candlestickMapping = dataTable.mapAs({
                    open: 1,
                    high: 2,
                    low: 3,
                    close: 4
                });

                var lineMapping = dataTable.mapAs({
                    value: 4
                });


                var scrollerData = anychart.data.table();
                scrollerData.addData(chartArr);
                var scollerMapping = scrollerData.mapAs({
                    open: 1,
                    high: 2,
                    low: 3,
                    close: 4
                });

                // map data for volume series
                var valueMapping = dataTable.mapAs({
                    value: 5
                });

                // create stock chart
                var chart = anychart.stock();
                chart.padding(0, 0, 0, 100)
                // chart.xScale('scatter');

                var candlestickPlot = chart.plot(0);

                var candlestickSeries;

                // if (_this.chartDate == 'd') {
                //     candlestickSeries = candlestickPlot.line(lineMapping);
                // } else {
                //     candlestickSeries = candlestickPlot.candlestick(candlestickMapping);
                // }
                candlestickSeries = candlestickPlot.candlestick(candlestickMapping);

                candlestickPlot.xGrid().enabled(true);
                candlestickPlot.yGrid().enabled(true);
                candlestickPlot.xAxis().showHelperLabel(false);
                candlestickPlot
                    .xAxis()
                    .labels()
                    .enabled(false);
                candlestickPlot.xAxis().height(0);
                candlestickPlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                candlestickPlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                candlestickPlot
                    .yAxis()
                    .labels()
                    .format(function () {
                        return _this.$js.formatNumber(this.value, 0, 0);
                    });

                console.log();

                candlestickSeries.name(_this.current_dsply_nmll);
                candlestickSeries.risingFill("#009900");
                candlestickSeries.fallingFill("#FF0000");
                candlestickSeries.legendItem().iconType("risingfalling");

                var candlestickSeriestooltip = candlestickSeries.tooltip();
                candlestickSeriestooltip.enabled(true);
                //candlestickSeriestooltip.format("Close: this.$js.formatNumber({%close}) \nOpen: {%open} \nHigh: {%high} \nLow: {%low}");


                // if (_this.chartDate != 'd') {
                //     candlestickSeriestooltip.format(function (e) {
                //         var close = this.close;
                //         var open = this.open;
                //         var high = this.high;
                //         var low = this.low;
                //         return (
                //             "Close: " +
                //             _this.$js.formatNumber(close, 0, 0) +
                //             "\nOpen: " +
                //             _this.$js.formatNumber(open, 0, 0) +
                //             "\nHigh: " +
                //             _this.$js.formatNumber(high, 0, 0) +
                //             "\nLow: " +
                //             _this.$js.formatNumber(low, 0, 0)
                //         );
                //     });
                // } else {
                //     candlestickSeriestooltip.format(function (e) {
                //         var close = this.value;
                //         return (
                //             _this.current_dsply_nmll + ": " +
                //             _this.$js.formatNumber(close, 0, 0)
                //         );
                //     });
                // }

                candlestickSeriestooltip.format(function (e) {
                    var close = this.close;
                    var open = this.open;
                    var high = this.high;
                    var low = this.low;
                    return (
                        "Close: " +
                        _this.$js.formatNumber(close, 0, 0) +
                        "\nOpen: " +
                        _this.$js.formatNumber(open, 0, 0) +
                        "\nHigh: " +
                        _this.$js.formatNumber(high, 0, 0) +
                        "\nLow: " +
                        _this.$js.formatNumber(low, 0, 0)
                    );
                });

                // create stock end

                // create and setup volume plot --1 start
                var volumePlot = chart.plot(1);
                volumePlot.height("20%");
                volumePlot.xGrid().enabled(true);
                volumePlot.yGrid().enabled(true);
                volumePlot.xAxis().showHelperLabel(false);
                volumePlot
                    .xAxis()
                    .labels()
                    .enabled(false);
                volumePlot.xAxis().height(0);
                volumePlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                volumePlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);

                var volumeSeries = volumePlot.column(valueMapping);
                volumeSeries.name("Volume");
                volumeSeries.fill("#5d5d5d");

                volumePlot
                    .yAxis()
                    .labels()
                    .format(function () {
                        var value = this.value;
                        value = parseInt(value) / 1000;
                        return _this.$js.formatNumber(value, 1, 0) + "K";
                    });

                var volumeSeriestooltip = volumeSeries.tooltip();
                volumeSeriestooltip.enabled(true);

                volumeSeriestooltip.format(function (e) {
                    var value = this.value;
                    value = parseInt(value) / 1000;
                    return "Volume: " + _this.$js.formatNumber(value, 1, 0) + "K";
                });
                // create and setup volume plot --1 end

                // create and setup volume plot --2 start
                var rsiPlot = chart.plot(2);
                rsiPlot.height("20%");
                rsiPlot.xGrid().enabled(true);
                rsiPlot.yGrid().enabled(true);
                rsiPlot.xAxis().showHelperLabel(false);
                rsiPlot
                    .xAxis()
                    .labels()
                    .enabled(true);
                rsiPlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                rsiPlot
                    .yAxis()
                    .labels()
                    .enabled(true)
                    .fontColor("#181515")
                    .fontSize(11);
                rsiPlot.yScale().maximum(100);
                rsiPlot.yScale().minimum(0);

                var rsi = rsiPlot
                    .rsi(candlestickMapping, $(".input_rsi").val())
                    .series();
                rsi.stroke("#64b5f6");

                if (_this.chartDate == 'd') {
                    rsiPlot
                        .xAxis()
                        .labels()
                        .format(function () {
                            // Set format for date time
                            //var hour = new Date(this.tickValue).getUTCHours().toString().padStart(2, "0");
                            //var min = new Date(this.tickValue).getUTCMinutes().toString().padStart(2, "0");
                            // return (Array(2).join(0) + hour).slice(-2) + ":" + (Array(2).join(0) + min).slice(-2);
                            var hour = new Date(this.tickValue).getUTCHours().toString().padStart(2, "0");
                            var min = new Date(this.tickValue).getUTCMinutes().toString().padStart(2, "0");
                            return (Array(2).join(0) + hour).slice(-2) + ":" + (Array(2).join(0) + min).slice(-2);

                            // return anychart.format.dateTime(
                            //     _this.$js.changeToLocalTime(this.value),
                            //     "HH:mm"
                            // );
                        });
                } else {
                    rsiPlot
                        .xAxis()
                        .labels()
                        .format(function () {
                            // Set format for date time
                            return anychart.format.dateTime(
                                _this.$js.changeToLocalTime(this.value),
                                "dd MMM"
                            );
                        });
                }


                var rsitooltip = rsi.tooltip();
                rsitooltip.enabled(true);
                rsitooltip.format(function (e) {
                    var seriesName = this.seriesName;
                    var value = this.value;
                    return seriesName + ": " + _this.$js.formatNumber(this.value, 1, 0);
                });
                // create and setup volume plot --2 end

                //sma
                if (_this.chartDate !== 'd') {
                    var smaline = candlestickPlot
                        .sma(candlestickMapping, $(".selector_ma").val())
                        .series();
                    smaline
                        .name("SMA(" + $(".selector_ma").val() + ")")
                        .stroke("#ff6d00");

                    var smalinetooltip = smaline.tooltip();
                    smalinetooltip.enabled(true);

                    smalinetooltip.format(function (e) {
                        var seriesName = this.seriesName;
                        var value = this.value;
                        return seriesName + ": " + _this.$js.formatNumber(this.value, 0, 0);
                    });
                }

                //bolling
                if ($(".checkbox_bb").is(":checked")) {
                    var bbands = candlestickPlot.bbands(
                        candlestickMapping,
                        $(".selector_bb").val(),
                        "spline",
                        "spline",
                        "spline"
                    );
                    bbands.upperSeries().stroke("#0000ff");
                    bbands.middleSeries().stroke("#00ff00");
                    bbands.lowerSeries().stroke("#0000ff");
                    bbands.rangeSeries().fill("#ffd54f 0.2");

                    var bbandsupperSeriestooltip = bbands.upperSeries().tooltip();
                    var bbandsmiddleSeriestooltip = bbands.middleSeries().tooltip();
                    var bbandslowerSeriestooltip = bbands.lowerSeries().tooltip();
                    var bbandsrangeSeriestooltip = bbands.rangeSeries().tooltip();
                    bbandsupperSeriestooltip.enabled(true);
                    bbandsmiddleSeriestooltip.enabled(true);
                    bbandslowerSeriestooltip.enabled(true);
                    bbandsrangeSeriestooltip.enabled(false);

                    bbandsupperSeriestooltip.format(function (e) {
                        var seriesName = this.seriesName;
                        var value = this.value;
                        return seriesName + ": " + _this.$js.formatNumber(this.value, 3, 0);
                    });

                    bbandsmiddleSeriestooltip.format(function (e) {
                        var seriesName = this.seriesName;
                        var value = this.value;
                        return seriesName + ": " + _this.$js.formatNumber(this.value, 3, 0);
                    });

                    bbandslowerSeriestooltip.format(function (e) {
                        var seriesName = this.seriesName;
                        var value = this.value;
                        return seriesName + ": " + _this.$js.formatNumber(this.value, 3, 0);
                    });
                }

                candlestickPlot.legend().useHtml(true);
                volumePlot.legend().useHtml(true);
                rsiPlot.legend().useHtml(true);

                // configure the format of legend items
                candlestickPlot.legend().itemsFormat(function () {
                    var series = this.series;

                    if (series.getType() == "candlestick") {
                        return (
                            series.name() +
                            ": (O: " +
                            _this.$js.formatNumber(this.open, 0, 0) +
                            ", H: " +
                            _this.$js.formatNumber(this.high, 0, 0) +
                            ", L: " +
                            _this.$js.formatNumber(this.low, 0, 0) +
                            ", C: " +
                            _this.$js.formatNumber(this.close, 0, 0) +
                            ")"
                        );
                    } else if (series.getType() == "line") {
                        return (
                            series.name() + ": " + _this.$js.formatNumber(this.value, 0, 0)
                        );
                    } else if (series.getType() == "range-area") {
                        return (
                            series.name() +
                            ": (H: " +
                            _this.$js.formatNumber(this.high, 0, 0) +
                            ", H: " +
                            _this.$js.formatNumber(this.high, 0, 0) +
                            ")"
                        );
                    } else if (series.getType() == "spline") {
                        return (
                            series.name() + ": " + _this.$js.formatNumber(this.value, 0, 0)
                        );
                    }
                });

                volumePlot.legend().itemsFormat(function () {
                    var series = this.series;
                    var value = this.value;
                    value = parseInt(value) / 1000;

                    return (
                        series.name() + ": " + _this.$js.formatNumber(value, 1, 0) + "K"
                    );
                });

                rsiPlot.legend().itemsFormat(function () {
                    var series = this.series;

                    return series.name() + ": " + _this.$js.formatNumber(this.value, 1, 0);
                });

                // create scroller series
                chart.scroller().area(candlestickMapping);
                chart.scroller().height("20px");
                if (_this.chartDate == 'd') {
                    var openmarket = false;
                    var fun_datenum = 1;
                    while (openmarket == false) {
                        fun_datenum--;
                        var tempdate = new Date(_this.fun_date(fun_datenum) + " 00:00:00 GMT+0");
                        for (var k = 0; k < fullchartArr.length; k++) {
                            if (fullchartArr[k][0] > tempdate) {
                                openmarket = open;
                            }
                        }
                    }
                    chart.selectRange(tempdate, fullchartArr[0][0]);
                } else if (_this.chartDate == 'w') {
                    chart.selectRange(_this.fun_date(-7), _this.getNowFormatDate());
                } else if (_this.chartDate == 'm') {
                    chart.selectRange(_this.getPassFormatDate(), _this.getNowFormatDate());
                } else if (_this.chartDate == '3m') {
                    chart.selectRange(_this.getPassFormatDate3(), _this.getNowFormatDate());
                } else if (_this.chartDate == '6m') {
                    chart.selectRange(_this.getPassFormatDate6(), _this.getNowFormatDate());
                } else {
                    chart.selectRange(_this.getPassYearFormatDate(), _this.getNowFormatDate());
                }
                chart.scroller().enabled(true)
                chart.scroller().orientation('top')
                chart.scroller().minHeight(35)
                chart.scroller().thumbs().autoHide(true)
                chart.scroller().selectedFill('#5bc2e7', '0.3')
                // var xScroller = chart.scroller();
                // xScroller.orientation('top');
                //enable chart scroll
                var listener = function (e) {
                    return true;
                };
                chart.listen("selectedrangechangestart", listener);
                chart.listen("selectedrangebeforechange", listener);
                chart.listen("selectedrangechange", listener);
                chart.listen("selectedrangechangefinish", listener);

                // set container id for the chart
                chart.container("chart-container");

                // initiate chart drawing
                chart.draw();
            });
        },
        // 获取列表数据
        getSearchData() {
            let _this = this;
            $.ajax({
                type: "POST",
                dataType: "json",
                url: this.$js.webservice + "GetScreenerData",
                data: {
                    token: "webkey",
                    underlying: this.postData.underlyingNanme,
                    type: "all",
                    issuer: "all",
                    maturity: "all",
                    moneyness: "all",
                    effectiveGearing: "all",
                    expiry: "all",
                    sortBy: this.postData.sortBy,
                    sortOrder: this.postData.sortOrder
                },
                success: function (res) {
                    if (res.status == "SUCCESS") {
                        _this.formData(res.data);
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        // 排列显示数据
        formData(data) {
            var formArr = [];
            for (var i = 0, max = data.length; i < max; i++) {
                var maturity = this.$js.spaceData(data[i].maturity);
                if (maturity !== "N/A") {
                    maturity = this.transitionData(
                        this.$js.changeToLocalTime(this.$js.formatDetailDate(maturity))
                    );
                }
                var sensitivity = data[i].sensitivity;
                if (sensitivity !== "N/A") {
                    sensitivity = this.$js.formatNumber(data[i].sensitivity, 1, 0);
                }
                var impliedVolalitiy = this.$js.spaceData(data[i].impliedVolalitiy);
                if (impliedVolalitiy !== "N/A") {
                    impliedVolalitiy = this.$js.formatNumber(impliedVolalitiy, 1, 0);
                }
                var effectiveGearing = this.$js.spaceData(data[i].effectiveGearing);
                if (effectiveGearing !== "N/A") {
                    effectiveGearing = this.$js.formatNumber(effectiveGearing, 1, 0);
                }
                var priceChange = this.$js.formatNumber(data[i].priceChange, 1, 0);
                if (priceChange > 0) {
                    priceChange = this.$js.changeRate(priceChange);
                } else if (priceChange == 0) {
                    priceChange = 0;
                }
                formArr[i] = [
                    this.$js.spaceData(data[i].dsply_nmll),
                    this.$js.rewriteIssueName(this.$js.spaceData(data[i].issuer)) == 'MSI' ? 'Maybank' : this.$js.rewriteIssueName(this.$js.spaceData(data[i].issuer)),
                    // this.$js.spaceData (data[i].underlying_name),
                    this.$js.formatNumber(data[i].exercisePrice, 0, 1),
                    this.$js.formatNumber(data[i].conv_ratio, 4, 0),
                    maturity,
                    sensitivity,
                    effectiveGearing,
                    this.$js.formatNumber(this.$js.spaceData(data[i].premium), 2 ,0),
                    this.$js.formatNumber(this.$js.clearRM(data[i].bidVolume), 0, 1),
                    this.$js.formatNumber(data[i].bidPrice, 0, 1),
                    this.$js.formatNumber(data[i].askPrice, 0, 1),
                    this.$js.formatNumber(this.$js.clearRM(data[i].askVolume), 0, 1),
                    this.$js.formatNumber(this.$js.clearRM(data[i].tradeVolume), 0, 1),
                    priceChange,
                    data[i].soldout,
                    data[i].ric
                ];
            }
            this.assignment(formArr);
        },
        // table赋值
        assignment(data) {
            let _this = this;
            $(".search-form-box tbody").empty();
            var formText = "";
            var soldout = "";
            for (var t = 0, max = data.length; t < max; t++) {
                if (Number(data[t][data[t].length - 2]) !== 0) {
                    soldout = "<br><span style='display: inline-block; color: red; font-size: 10px; border: 1px solid; padding: 2px 4px; white-space: nowrap;'>Low On Inventory</span>"
                } else {
                    soldout = ""
                }
                if (data[t][1] !== "RHB") {
                    formText += '<tr class="form_c">';
                    $.each(data[t], function (j, v) {
                        if (j < 14) {
                            if (j < 2) {
                                if (j === 0) {
                                    if (data[t][data[t].length - 1] !== " ") {
                                        formText +=
                                            '<td class="search-td-h"><a onclick="getRicToDetails(\'' +
                                            data[t][data[t].length - 1] +
                                            "'," +
                                            " '" +
                                            data[t][0] +
                                            "')\">" +
                                            v + soldout +
                                            "</a></td>";
                                    } else {
                                        formText +=
                                            '<td class="search-td-h"> <a>' + v + soldout + "</a></td>";
                                    }
                                } else {
                                    formText += "<td>" + v + "</td>";
                                }
                            } else {
                                formText += '<td class="c_one_1">' + v + "</td>";
                            }
                        } else {
                            if (j === 19) {
                                if (v > 0) {
                                    formText +=
                                        '<td class="c_two_1" style="color: rgb(0, 153, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v < 0) {
                                    formText +=
                                        '<td class="c_two_1" style="color: rgb(255, 0, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v == 0) {
                                    formText += '<td class="c_two_1">' + v + "</td>";
                                }
                            } else {
                                formText += '<td class="c_two_1">' + v + "</td>";
                            }
                        }
                    });
                    formText += "</tr>";
                } else {
                    formText += '<tr class="form_c">';
                    $.each(data[t], function (j, v) {
                        if (j < 14) {
                            if (j < 2) {
                                if (j === 0) {
                                    if (data[t][data[t].length - 1] !== " ") {
                                        formText +=
                                            '<td class="search-td-h"><a onclick="getRicToDetails(\'' +
                                            data[t][data[t].length - 1] +
                                            "'," +
                                            " '" +
                                            data[t][0] +
                                            "')\">" +
                                            v +
                                            "</a>" + soldout + "</td>";
                                    } else {
                                        formText +=
                                            '<td class="search-td-h"> <a>' + v + "</a>" + soldout + "</td>";
                                    }
                                } else {
                                    formText += '<td class="tdRhb">' + v + "</td>";
                                }
                            } else {
                                formText += '<td class="c_one_1 tdRhb">' + v + "</td>";
                            }
                        } else {
                            if (j === 19) {
                                if (v > 0) {
                                    formText +=
                                        '<td class="c_two_1 tdRhb" style="color: rgb(0, 153, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v < 0) {
                                    formText +=
                                        '<td class="c_two_1 tdRhb" style="color: rgb(255, 0, 0)">' +
                                        v +
                                        "</td>";
                                } else if (v == 0) {
                                    formText += '<td class="c_two_1 tdRhb">' + v + "</td>";
                                }
                            } else {
                                formText += '<td class="c_two_1 tdRhb">' + v + "</td>";
                            }
                        }
                    });
                    formText += "</tr>";
                }
            }
            $(".search-form-box tbody").append(formText);
            window.getRicToDetails = function (ric, dsplay_nmll) {
                localStorage.setItem("detailsRic", ric);
                let routes = _this.$router.resolve({
                    path: "/id/WarrantDetails",
                    query: {
                        dsplay_nmll: dsplay_nmll
                    }
                });
                location.href = routes.href;
            };
        },
        // 排序
        selectSort() {
            let _this = this;
            var sortArr = [
                "wcode",
                "isr",
                "ep",
                "dwps",
                "mdate",
                "sen",
                "eg",
                "pre",
                "bv",
                "bp",
                "ap",
                "av",
                "tv",
                "pc"
            ];
            $(".search-form-name")
                .find(".asc")
                .click(function () {
                    var ascIndex = $(".search-form-name")
                        .find(".asc")
                        .index($(this));
                    _this.postData.sortBy = sortArr[ascIndex];
                    _this.postData.sortOrder = "asc";
                    // this.postData.estimate = false
                    _this.getSearchData();
                });
            $(".search-form-name")
                .find(".desc")
                .click(function () {
                    var descIndex = $(".search-form-name")
                        .find(".desc")
                        .index($(this));
                    _this.postData.sortBy = sortArr[descIndex];
                    _this.postData.sortOrder = "desc";
                    // this.postData.estimate = false
                    _this.getSearchData();
                });
        },
        // 时间转换
        transitionData(time) {
            var MMM = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            return (
                time.getDate() +
                " " +
                MMM[time.getMonth()] +
                " " +
                time
                    .getFullYear()
                    .toString()
                    .substring(4 - 2, 4)
            );
        },

        onlyNumberKey(evt) {
            // Only ASCII charactar in that range allowed
            var ASCIICode = evt.which ? evt.which : evt.keyCode;
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
            return true;
        },
        //1d
        getNowFormatDate() {
            var date = new Date();
            var seperator1 = "/";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        },
        //1w +往后 -往前
        fun_date(aa) {
            var date1 = new Date(),
                time1 =
                    date1.getFullYear() +
                    "/" +
                    (date1.getMonth() + 1) +
                    "/" +
                    date1.getDate(); //time1表示当前时间
            var date2 = new Date(date1);
            date2.setDate(date1.getDate() + aa);
            var time2 =
                date2.getFullYear() +
                "/" +
                (date2.getMonth() + 1) +
                "/" +
                date2.getDate();
            console.log(time2);
            return time2;
        },
        //1m
        getPassFormatDate() {
            var nowDate = new Date();
            var date = new Date(nowDate);
            date.setDate(date.getDate() - 30);
            var seperator1 = "/";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            console.log(currentdate);
            return currentdate;
        },
        getPassFormatDate3() {
            var nowDate = new Date();
            var date = new Date(nowDate);
            date.setDate(date.getDate() - 30 * 3);
            var seperator1 = "/";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            console.log(currentdate, 11111);
            return currentdate;
        },
        getPassFormatDate6() {
            var nowDate = new Date();
            var date = new Date(nowDate);
            date.setDate(date.getDate() - 30 * 6);
            var seperator1 = "/";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            console.log(currentdate, 2222);
            return currentdate;
        },
        //1y
        getPassYearFormatDate() {
            var nowDate = new Date();
            var date = new Date(nowDate);
            date.setDate(date.getDate() - 365);
            var seperator1 = "/";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }

            var currentdate = year + seperator1 + month + seperator1 + strDate;
            console.log(currentdate);
            return currentdate;
        }
    }
};